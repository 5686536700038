import { Controller } from "stimulus";
import "bootstrap";
import $ from "jquery";

// require("select2/dist/css/select2");
// require("select2-bootstrap-theme/dist/select2-bootstrap");

import Select2 from "select2";

export default class extends Controller {
  connect() {
    $(".search-select-stimulus").select2({
      theme: "bootstrap-5",
    });

    $(".search-select-tags").select2({
      theme: "bootstrap-5",
      tags: true,
    });

    $(".search-select-tags-separator").select2({
      theme: "bootstrap-5",
      tags: true,
      tokenSeparators: [",", ", "],
    });
  }

  disconnect() {}
}
