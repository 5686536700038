import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";
const _ = require("lodash");
export default class extends Controller {
  connect() {
    let that = this;

    $("select").on(
      "select2:select select2:clear select2:unselect",
      function () {
        let event = new Event("change", { bubbles: true }); // fire a native event
        this.dispatchEvent(event);
      }
    );

    that.element.getElementsByTagName("select").forEach((item) => {
      item.addEventListener("change", _.debounce(that.handleChange, 500));
    });

    that.element.querySelectorAll(".datepicker-stimulus").forEach((item) => {
      item.addEventListener("change", _.debounce(that.handleChange, 500));
    });

    that.element.querySelectorAll(".monthpicker-stimulus").forEach((item) => {
      item.addEventListener("change", _.debounce(that.handleChange, 500));
    });

    that.element.getElementsByTagName("input").forEach((item) => {
      item.addEventListener("keyup", _.debounce(that.handleChange, 500));
    });

    that.element.getElementsByTagName("input").forEach((item) => {
      if (item.type == "checkbox" || item.type == "radio") {
        item.addEventListener("change", _.debounce(that.handleChange, 500));
      }
    });
  }
  handleChange(event) {
    event.preventDefault();
    // event.target.name // => "user[answer]"
    // event.target.value // => <user input string>
    event.target.form.requestSubmit();
    let element = document.getElementById("items_table");
    if (element) {
      element.addEventListener(
        "turbo:frame-load",
        navigator.history.push(new URL(element.src))
      );
    }
  }
}
