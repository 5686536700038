// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "popper.js";

global.jQuery = require("jquery");
global.$ = require("jquery");
require("src/cocoon");
import "trix";
import "@rails/actiontext";

import "bootstrap";
import "@fortawesome/fontawesome-free/js/all.js";
import "flag-icon-css/css/flag-icons.css";
import "./stylesheets/application.scss";

import "chartkick/chart.js";

Chartkick.configure({ language: "it" });

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

import "controllers";

// require("@rails/actiontext")
