import { Controller } from "stimulus";
import $ from "jquery";
import { get, post } from "@rails/request.js";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "updateType",
    "templateId",
    "mappingForm",
    "newBrand",
    "newModel",
    "brand",
    "model",
    "createModelFields",
    "selectModelFields",
    "newModelModal",
    "newBrandModal",
  ];

  connect() {
    $(".next-car").on("click", function (event) {
      event.preventDefault();
      $(".nav-pills > .nav-link.active").next("button").trigger("click");
    });

    $(".previous-car").on("click", function (event) {
      event.preventDefault();
      $(".nav-pills > .nav-link.active").prev("button").trigger("click");
    });

    this.modelModal = new Modal(this.newModelModalTarget);
    this.brandModal = new Modal(this.newBrandModalTarget);
  }

  async createBrand(event) {
    let newBrand = this.newBrandTarget.value;
    let url = event.target.dataset.url;

    if (newBrand == "" || newBrand == null) {
      alert("ATTENZIONE: inserire un marchio");
    } else {
      const response = await post(url, {
        body: JSON.stringify({
          brand: newBrand,
          target: this.brandTarget.id,
        }),
        responseKind: "turbo-stream",
      });
      let event = new Event("change", { bubbles: true });
      this.brandTarget.dispatchEvent(event);
      this.hideCreateBrandFields();
    }
  }

  async createModel(event) {
    let newModel = this.newModelTarget.value;
    let url = event.target.dataset.url;
    let brand_id = this.brandTarget.selectedOptions[0].value;

    if (brand_id == "" || brand_id == null) {
      alert("ATTENZIONE: selezionare un marchio");
    } else if (newModel == "" || newModel == null) {
      alert("ATTENZIONE: inserire un modello");
    } else {
      const response = await post(url, {
        body: JSON.stringify({
          model: newModel,
          brand_id: brand_id,
          target: this.modelTarget.id,
        }),
        responseKind: "turbo-stream",
      });
      this.hideCreateModelFields();
    }
  }

  showCreateModelFields() {
    this.modelModal.show();
  }
  hideCreateModelFields() {
    this.modelModal.hide();
  }

  showCreateBrandFields() {
    this.brandModal.show();
  }
  hideCreateBrandFields() {
    this.brandModal.hide();
  }
}
