import { Controller } from "stimulus";
import "bootstrap";
// import $ from "jquery";

// require("select2/dist/css/select2");
// require("select2-bootstrap-theme/dist/select2-bootstrap");

import Select2 from "select2";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

export default class extends Controller {
  connect() {
    let that = this;
    let datePickers = that.element.querySelectorAll(
      ".datepicker-stimulus:not(.flatpickr-input)"
    );
    if (that.element.classList.contains("datepicker-stimulus")) {
      datePickers = [datePickers, that.element];
    }
    flatpickr(datePickers, {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      locale: "it",
    });

    let dateTimePickers = that.element.querySelectorAll(
      ".datetimepicker-stimulus:not(.flatpickr-input)"
    );
    if (that.element.classList.contains("datetimepicker-stimulus")) {
      dateTimePickers = [dateTimePickers, that.element];
    }
    flatpickr(dateTimePickers, {
      altInput: true,
      altFormat: "d/m/Y H:i",
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      locale: "it",
      time_24hr: true,
    });

    let monthPickers = that.element.querySelectorAll(
      ".monthpicker-stimulus:not(.flatpickr-input)"
    );
    if (that.element.classList.contains("monthpicker-stimulus")) {
      monthPickers = [monthPickers, that.element];
    }
    flatpickr(monthPickers, {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      locale: "it",
      plugins: [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: "Y-m-d", //defaults to "F Y"
          altFormat: "F Y", //defaults to "F Y"
          theme: "light", // defaults to "light"
        }),
      ],
    });
  }

  disconnect() {}
}
