import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["updateType", "templateId", "mappingForm"];

  connect() {
    $(".import-template-select").on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }

  updateTemplate() {
    this.updateTypeTarget.value = "template_update";
    event.target.form.requestSubmit();
  }

  newTemplate(event) {
    event.preventDefault();
    this.updateTypeTarget.value = "new_template";
    this.mappingFormTarget.requestSubmit();
  }

  existingTemplate(event) {
    event.preventDefault();
    this.updateTypeTarget.value = "template_update";
    this.mappingFormTarget.requestSubmit();
  }

  renameTemplate() {
    event.preventDefault();
    this.updateTypeTarget.value = "template_rename";
    this.mappingFormTarget.requestSubmit();
  }
}
