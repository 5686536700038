import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "actionsContainer", "emails"];

  connect() {
    console.log("xx");
  }

  showHideActions() {
    let showActions = false;
    let emailValues = [];
    this.checkboxTargets.forEach((element) => {
      let value = element.checked;
      if (value) {
        showActions = true;
        if (element.dataset.email && element.dataset.email != "") {
          emailValues.push(element.dataset.email);
        }
        element.closest("tr").classList.add("table-warning");
      } else {
        element.closest("tr").classList.remove("table-warning");
      }
    });
    if (showActions) {
      this.actionsContainerTargets.forEach((element) => {
        element.classList.remove("d-none");
      });
    } else {
      this.actionsContainerTargets.forEach((element) => {
        element.classList.add("d-none");
      });
    }
    this.emailsTarget.value = emailValues.join(", ");
  }
}
