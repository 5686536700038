import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";
import axios from "axios";

export default class extends Controller {
  static values = {
    status: String,
  };

  connect() {}
  handleChange(event) {
    event.preventDefault();

    let reminderId = event.target.dataset.reminderId;
    let value = event.target.value;
    let field = event.target.name;

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios
      .post("/reminders/update-value/" + reminderId, {
        field: field,
        value: value,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }
  handleChecked(event) {
    event.preventDefault();

    let reminderId = event.target.dataset.reminderId;
    let value = event.target.checked;
    let field = event.target.name;

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios
      .post("/reminders/update-value/" + reminderId, {
        field: field,
        value: value,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }
}
