import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {}

  checkCompletion(event) {
    if (event.detail.formSubmission.result.success) {
      $("#newCustomerUpdateModal").modal("hide");
      Turbo.visit(location.toString());
    }
  }

  checkModal(event) {}
}
