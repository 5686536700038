import CheckboxSelectAll from "stimulus-checkbox-select-all";

export default class extends CheckboxSelectAll {
  connect() {
    super.connect();

    // Get all checked checkboxes
    // this.checked;

    // Get all unchecked checkboxes
    // this.unchecked;
  }
}
