import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";
import axios from "axios";

export default class extends Controller {
  static values = {
    status: String,
  };

  connect() {
    if (document.getElementById("percentage-text")) {
      document.getElementById("percentage-text").innerHTML = this.statusValue;
      let className = "progress-" + this.statusValue;
      document.getElementById("percentage-circle").className = "";
      document
        .getElementById("percentage-circle")
        .classList.add("progress-circle");
      document.getElementById("percentage-circle").classList.add(className);
    }
  }

  handleChange(event) {
    event.preventDefault();

    let carId = event.target.dataset.carId;
    let value = event.target.value;
    let field = event.target.name;

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios
      .post("/batch/update-car/" + carId, {
        field: field,
        value: value,
      })
      .then((response) => {
        if (document.getElementById("percentage-text")) {
          let percentage = response.data.percentage;
          document.getElementById("percentage-text").innerHTML = percentage;
          let className = "progress-" + percentage;
          document.getElementById("percentage-circle").className = "";
          document
            .getElementById("percentage-circle")
            .classList.add("progress-circle");
          document.getElementById("percentage-circle").classList.add(className);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
