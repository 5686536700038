import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "supplierId",
    "buyerId",
    "supplierCommission",
    "buyerCommission",
  ];

  connect() {
    console.log("info controller");
    $("#info-batch-card").on("select2:select", () => {
      this.updateCommissions();
    });
  }

  updateCommissions() {
    const token = document.querySelector("[name=csrf-token]").content;

    let supplierId = this.supplierIdTarget.value;
    if (supplierId != "" && this.hasSupplierCommissionTarget) {
      let customer_url = "/customers/" + supplierId + ".json";
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios
        .get(customer_url)
        .then((response) => {
          this.supplierCommissionTarget.value =
            response.data.standard_supplier_commission;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    let buyerId = this.buyerIdTarget.value;
    if (buyerId != "" && this.hasBuyerCommissionTarget) {
      let buyer_url = "/customers/" + buyerId + ".json";
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
      axios
        .get(buyer_url)
        .then((response) => {
          this.buyerCommissionTarget.value =
            response.data.standard_buyer_commission;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
