import { Controller } from "stimulus";
import { get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["select"];
  static values = {
    url: String,
    param: String,
  };

  connect() {
    $("select").on(
      "select2:select select2:clear select2:unselect",
      function () {
        let event = new Event("change", { bubbles: true }); // fire a native event
        this.dispatchEvent(event);
      }
    );

    if (this.selectTarget.id === "") {
      this.selectTarget.id = Math.random().toString(36);
    }
  }

  change(event) {
    let params = new URLSearchParams();
    params.append(this.paramValue, event.target.selectedOptions[0].value);
    params.append("target", this.selectTarget.id);

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream",
    });
  }
}
